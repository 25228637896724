//Telas
@import "../telas/AceitacaoDeTermos/styles.scss";

.u-cor-do-tema-primaria {
  color: var(--cor-primaria);
}

.u-cor-do-tema-secundaria {
  color: var(--cor-secundaria);
}

.u-cor-do-tema-terciaria {
  color: var(--cor-terciaria);
}
