.titulo {
  margin-top:24px;
  margin-bottom: 16px;
}

.texto {
  margin-bottom: 16px;
}

.cabecalho-sistema__logo {
  height: 32px;
}

.cabecalho-sistema__container-primario,
.cabecalho-sistema__container-secundario {
  height: 100%;
}

.cabecalho-sistema {
  height: 56px;
}

.rodape-termo-de-aceite {
  background-color: #fff;
  padding: 40px 24px;
}

.u-termo-de-aceite-largura-maxima {
  width: 100%;
  margin: 0 auto;
  max-width: 752px;
  display: flex;
  padding: 0 24px;
}

.container {
  width: 100%;
  margin: 0;
  margin-bottom: 40px !important;
}
